import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import { BsEye, BsTrash3, BsPencil, BsCloudDownload, BsLink45Deg, BsCheckCircle, BsCheckCircleFill } from 'react-icons/bs';
import MessagePopup from '../Alerts/MessagePopup';
import ErrorPopup from '../Alerts/ErrorPopup';
import './Forms.css';

export function FormInstancesElement({ formInstance, onDeleteFormInstance }) {
    console.log(formInstance);
    const navigate = useNavigate();
    const [formInstanceState, setFormInstanceState] = useState(formInstance);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);

    const handleUpdate = (form_instance_uuid, completed) => {
        api.post('/api/forms/form_istance/', { form_instance_uuid})
        .then((response) => {
            console.log('OK');
        })
        .catch((error) => {
            setError('Ezin izan da galdetegia amaitutzat eman.')
        });
    };

    return (
        <>
            <MessagePopup message={message} onClose={() => setMessage(null)} />
            <ErrorPopup message={error} onClose={() => setError(null)} />
            <tr>
                <td>{formInstance.name}</td>
                <td>{formInstance.surname_1}</td>
                <td>{formInstance.surname_2}</td>
                <td>{formInstance.gender}</td>
                <td>{formInstance.birthdate}</td>
                <td>{formInstance.address}</td>
                <td>{formInstance.location}</td>
                <td>{formInstance.zip_code}</td>
                <td>{formInstance.nationality}</td>
                <td>{formInstance.document_type}</td>
                <td>{formInstance.document_number}</td>
                <td>{formInstance.expedition_date}</td>
                <td>{formInstance.expiration_date}</td>
                <td>{formInstance.phone}</td>
                <td>{formInstance.tutor_fullname}</td>
                <td>{formInstance.tutor_document_type}</td>
                <td>{formInstance.tutor_document_number}</td>
                <td>{formInstance.tutor_email}</td>
                <td>{formInstance.tutor_phone}</td>
                <td>{formInstance.tutor_fullname_2}</td>
                <td>{formInstance.tutor_document_type_2}</td>
                <td>{formInstance.tutor_document_number_2}</td>
                <td>{formInstance.tutor_email_2}</td>
                <td>{formInstance.tutor_phone_2}</td>
                <td>{formInstance.food_allergies}</td>
                <td>{formInstance.other_allergies}</td>
                <td>{formInstance.diseases}</td>
                <td>{formInstance.communicate}</td>
                <td>{formInstance.classroom}</td>
                <td>{formInstance.shirt_size}</td>
                <td>{formInstance.adventure_activities}</td>
                <td>{formInstance.record_images_responsable_checked}</td>
                <td>{formInstance.record_images_social_media_checked}</td>
            </tr>
        </>
    );
}
