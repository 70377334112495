import React, { Fragment, useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../api/api";
import { BsPlusCircleFill, BsX } from 'react-icons/bs';
import SuccessPopup from "../Alerts/SuccessPopup";
import ErrorPopup from "../Alerts/ErrorPopup";
import WarningPopup from "../Alerts/WarningPopup";

import "./Forms.css";

export function EditForm() {
    const navigate = useNavigate();
    const { formId } = useParams();
    const [destinationOptions, setDestinationOptions] = useState([]);
    const [courseOptions, setCourseOptions] = useState([]);
    const [adventureActivityOptions, setAdventureActivityOptions] = useState([]);
    const [success, setSuccess] = useState(null);
    const [error, setError] = useState(null);
    const [warning, setWarning] = useState(null);
    const [isActive, setIsActive] = useState(null);
    const [isCompleted, setIsCompleted] = useState(null);
    const [numCompletedStudents, setNumCompletedStudents] = useState(null);

    const [formData, setFormData] = useState({
        id: "",
        name: "",
        validationDate: "",
        insistentWarnings: false,
        startDate: "",
        endDate: "",
        numStudents: 0,
        destination: "",
        course: "",
        cif: "",
        schoolName: "",
        schoolCif: "",
        schoolAddress: "",
        schoolDirectorName: "",
        schoolDirectorDocumentId: "",
        schoolDirectorEmail: "",
        representantName: "",
        representantDocumentId: "",
        representantEmail: "",
        contactEmail: "",
        journeyInformationFile_eu: null,
        journeyInformationFile: null,
        contractGeneralFile_eu: null,
        contractGeneralFile_es: null,
        contractSpecificFile_eu: null,
        contractSpecificFile_es: null,
        insuranceFile_eu: null,
        insuranceFile_es: null,
        adventureActivity: false,
        adventureActivitySelected: "",
        choiceOfActions: false,
        choiceOfActionsJson: [],
        rules: false,
        rulesFile_eu: null,
        rulesFile_es: null,
        summerExperiences: false
    });

    useEffect(() => {
        const fetchDestinationOptions = async () => {
            api.get('/api/forms/destination_list/')
            .then((response) => {
                setDestinationOptions(response.data);
            })
            .catch((error) => {
                setError('Ezin izan dira helmugak eskuratu.');
            });
        };

        const fetchCourseOptions = async () => {
            api.get('/api/forms/course_list/')
            .then((response) => {
                setCourseOptions(response.data);
            })
            .catch((error) => {
                setError('Ezin izan dira kurtsoaren aukerak eskuratu.');
            });
        };

        const fetchAdventureActivities = async () => {
            api.get('/api/forms/adventure_activities_list/')
            .then((response) => {
                setAdventureActivityOptions(response.data);
            })
            .catch((error) => {
                setError('Ezin zain dira abenturazko ekintzak eskuratu.');
            });
        };

        fetchDestinationOptions();
        fetchCourseOptions();
        fetchAdventureActivities();
    }, []);

    useEffect(() => {
        api.get(`/api/forms/form/?form_id=${formId}`)
        .then(response => {
            const {
                id,
                name,
                validation_date,
                insistent_warnings,
                start_date,
                end_date,
                num_students,
                destination,
                course,
                cif,
                school_name,
                school_cif,
                school_address,
                school_director_name,
                school_director_document_id,
                school_director_email,
                representant_name,
                representant_document_id,
                representant_email,
                contact_email,
                journey_information_file_eu,
                journey_information_file_es,
                contract_general_file_eu,
                contract_general_file_es,
                contract_specific_file_eu,
                contract_specific_file_es,
                insurance_file_eu,
                insurance_file_es,
                adventure_activity,
                adventure_activity_selected,
                choice_of_actions,
                choice_of_actions_json,
                rules,
                rules_file_eu,
                rules_file_es,
                summer_experiences,
                is_active,
                completed,
                num_students_completed
            } = response.data;
            setIsActive(is_active);
            setIsCompleted(completed)
            setNumCompletedStudents(num_students_completed);

            setFormData({
                id: id || "",
                name: name || "",
                validationDate: validation_date || "",
                insistentWarnings: insistent_warnings || false,
                startDate: start_date || "",
                endDate: end_date || "",
                numStudents: num_students || 0,
                destination: destination || "",
                course: course || "",
                cif: cif || false,
                schoolName: school_name || "",
                schoolCif: school_cif || "",
                schoolAddress: school_address || "",
                schoolDirectorName: school_director_name || "",
                schoolDirectorDocumentId: school_director_document_id || "",
                schoolDirectorEmail: school_director_email || "",
                representantName: representant_name || "",
                representantDocumentId: representant_document_id || "",
                representantEmail: representant_email || "",
                contactEmail: contact_email || "",
                journeyInformationFile_eu: journey_information_file_eu || null,
                journeyInformationFile_es: journey_information_file_es || null,
                contractGeneralFile_eu: contract_general_file_eu || null,
                contractGeneralFile_es: contract_general_file_es || null,
                contractSpecificFile_eu: contract_specific_file_eu || null,
                contractSpecificFile_es: contract_specific_file_es || null,
                insuranceFile_eu: insurance_file_eu || null,
                insuranceFile_es: insurance_file_es || null,
                adventureActivity: adventure_activity || false,
                adventureActivitySelected: adventure_activity_selected || "",
                choiceOfActions: choice_of_actions || false,
                choiceOfActionsJson: choice_of_actions_json || [],
                rules: rules || false,
                rulesFile_eu: rules_file_eu || null,
                rulesFile_es: rules_file_es || null,
                summerExperiences: summer_experiences || false
            });
        })
        .catch(error => {
            setError("Ezin izan da galdetegiaren informazioa kargatu.");
        });
    }, [formId]);

    const newActionRefsEU = useRef([]);
    const newActionRefsES = useRef([]);

    const handleChange = (e) => {
        const { name, value, type, checked, files } = e.target;

        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : type === "file" ? files[0] : value,
        });
    };

    const validateDates = () => {
        const validationDate = new Date(formData.validationDate);
        const startDate = new Date(formData.startDate);
        const endDate = new Date(formData.endDate);

        if (validationDate > startDate) {
            setError('Hasiera data ezin da balidazio data baino txikiagoa izan.');
            return;
        }

        if (startDate > endDate) {
            setError('Amaiera data ezin da hasiera data baino txikiagoa izan.');
            return;
        }
    };

    const validateCif = () => {
        const cif = formData.schoolCif;
        const regexPattern1 = /^[a-zA-Z]\d{8}$/; // Formato AXXXXXXXX
        const regexPattern2 = /^[a-zA-Z]\d{7}[a-zA-Z]$/; // Formato AXXXXXXXA
    
        if (!regexPattern1.test(cif) && !regexPattern2.test(cif)) {
            setWarning('Kontuz! Badirudi CIFak formatu okerra duela.')
        }
    };

    const validateNanCIF = () => {
        const nan = formData.schoolDirectorDocumentId;
        const regexPattern = /^[0-9]{8}[A-Za-z]$/; // 8 dígitos seguidos de una letra
    
        if (!regexPattern.test(nan)) {
            setWarning('Kontuz! NANak formatu okerra du.');
        }
    };

    const validateNanNotCIF = () => {
        const nan = formData.representantDocumentId;
        const regexPattern = /^[0-9]{8}[A-Za-z]$/; // 8 dígitos seguidos de una letra
    
        console.log(nan);

        if (!regexPattern.test(nan)) {
            setWarning('Kontuz! NANak formatu okerra du.');
        }
    };

    // Agrega las nuevas funciones
    const handleAddDay = () => {
        setFormData((prevData) => ({
            ...prevData,
            choiceOfActionsJson: [
                ...prevData.choiceOfActionsJson,
                {
                    dayNumber: prevData.choiceOfActionsJson.length + 1,
                    choiceOfActionsMult: false,
                    question_eu: '',
                    question_es: '',
                    activities_eu: [],
                    activities_es: []
                }
            ]
        }));
    };

    const handleRemoveDay = (dayIndex) => {
        setFormData((prevData) => {
            const updatedDays = [...prevData.choiceOfActionsJson];
            updatedDays.splice(dayIndex, 1);
            const renumberedDays = updatedDays.map((day, index) => ({
                ...day,
                dayNumber: index + 1
            }));
            return {
                ...prevData,
                choiceOfActionsJson: renumberedDays
            };
        });
    };

    const handleDayMultChange = (e, dayIndex) => {
        const { checked } = e.target;
        setFormData((prevData) => {
            const updatedDays = [...prevData.choiceOfActionsJson];
            updatedDays[dayIndex].choiceOfActionsMult = checked;
            return {
                ...prevData,
                choiceOfActionsJson: updatedDays
            };
        });
    };

    const handleAddActionEU = (e, dayIndex) => {
        e.preventDefault();
        const newItem = newActionRefsEU.current[dayIndex].value.trim();
        if (newItem) {
            setFormData((prevData) => {
                const updatedDays = [...prevData.choiceOfActionsJson];
                // Verificar si 'activities_eu' existe, si no, inicializarlo como un array vacío
                if (!updatedDays[dayIndex].activities_eu) {
                    updatedDays[dayIndex].activities_eu = [];
                }
                updatedDays[dayIndex].activities_eu.push(newItem);
                return {
                    ...prevData,
                    choiceOfActionsJson: updatedDays
                };
            });
            newActionRefsEU.current[dayIndex].value = '';
        }
    };

    const handleRemoveActionEU = (dayIndex, activityIndex) => {
        setFormData((prevData) => {
            const updatedDays = [...prevData.choiceOfActionsJson];
            updatedDays[dayIndex].activities_eu.splice(activityIndex, 1);
            return {
                ...prevData,
                choiceOfActionsJson: updatedDays
            };
        });
    };

    const handleAddActionES = (e, dayIndex) => {
        e.preventDefault();
        const newItem = newActionRefsES.current[dayIndex].value.trim();
        if (newItem) {
            setFormData((prevData) => {
                const updatedDays = [...prevData.choiceOfActionsJson];
                // Verificar si 'activities_eu' existe, si no, inicializarlo como un array vacío
                if (!updatedDays[dayIndex].activities_es) {
                    updatedDays[dayIndex].activities_es = [];
                }
                updatedDays[dayIndex].activities_es.push(newItem);
                return {
                    ...prevData,
                    choiceOfActionsJson: updatedDays
                };
            });
            newActionRefsES.current[dayIndex].value = '';
        }
    };

    const handleRemoveActionES = (dayIndex, activityIndex) => {
        setFormData((prevData) => {
            const updatedDays = [...prevData.choiceOfActionsJson];
            updatedDays[dayIndex].activities_es.splice(activityIndex, 1);
            return {
                ...prevData,
                choiceOfActionsJson: updatedDays
            };
        });
    };

    const handleDayQuestionChangeEU = (e, dayIndex) => {
        const { value } = e.target;
        setFormData((prevData) => {
            const updatedDays = [...prevData.choiceOfActionsJson];
            updatedDays[dayIndex].question_eu = value;
            return {
                ...prevData,
                choiceOfActionsJson: updatedDays
            };
        });
    };

    const handleDayQuestionChangeES = (e, dayIndex) => {
        const { value } = e.target;
        setFormData((prevData) => {
            const updatedDays = [...prevData.choiceOfActionsJson];
            updatedDays[dayIndex].question_es = value;
            return {
                ...prevData,
                choiceOfActionsJson: updatedDays
            };
        });
    };

    const validateForm = () => {
        const emailRegex = /^[-!#$%&'*+/=?^_`{}|~0-9A-Z]+(\.[-!#$%&'*+/=?^_`{}|~0-9A-Z]+)*@(?:[A-Z0-9](?:[A-Z0-9-]{0,61}[A-Z0-9])?\.)+(?:[A-Z]{2,6}\.?|[A-Z0-9-]{2,}\.?)$/i;

        const fieldNames = {
            name: 'Izena',
            validationDate: 'Balidazio data',
            startDate: 'Hasiera data',
            endDate: 'Amaitze data',
            numStudents: 'Ikasle kopurua',
            destination: 'Helmuga',
            course: 'Kurtsoa',
            contactEmail: 'Kontaktu emaila',
            journeyInformationFile_eu: 'Aukeratutakoa',
            journeyInformationFile_es: 'Aukeratutakoa',
            contractGeneralFile_eu: 'Kontratu orokorra',
            contractGeneralFile_es: 'Kontratu orokorra',
            contractSpecificFile_eu: 'Kontratu zehatza',
            contractSpecificFile_es: 'Kontratu zehatza',
            insuranceFile_eu: 'Asegurua',
            insuranceFile_es: 'Asegurua',
            rulesFile_eu: 'Arauak',
            rulesFile_es: 'Arauak',
            schoolName: 'Ikastetxearen izena',
            schoolCif: 'Ikastetxearen CIFa',
            schoolAddress: 'Ikastetxearen helbidea',
            schoolDirectorName: 'Zuzendariaren izena',
            schoolDirectorDocumentId: 'Zuzendaria NANa',
            schoolDirectorEmail: 'Zuzendaria emaila',
            representantName: 'Ordezkariaren izena',
            representantDocumentId: 'Ordezkariaren NANa',
            representantEmail: 'Ordezkariaren emaila',
            adventureActivitySelected: 'Abenturazko ekintzak'
        };

        const requiredFields = [
            "name",
            "validationDate",
            "startDate",
            "endDate",
            "numStudents",
            "destination",
            "course",
            "contactEmail",
            "journeyInformationFile_eu",
            "journeyInformationFile_es",
            "contractGeneralFile_eu",
            "contractGeneralFile_es",
            "contractSpecificFile_eu",
            "contractSpecificFile_es",
            "insuranceFile_eu",
            "insuranceFile_es"
        ];

        if (formData.cif) {
            requiredFields.push(
                "schoolName",
                "schoolCif",
                "schoolAddress",
                "schoolDirectorName",
                "schoolDirectorDocumentId",
                "schoolDirectorEmail"
            );
            if (!emailRegex.test(formData.schoolDirectorEmail)) {
                const fieldName = fieldNames["schoolDirectorEmail"] || "schoolDirectorEmail";
                setError(`${fieldName} eremuak formatu baliogabea du.`);
                return false;
            }
        } else {
            requiredFields.push(
                "representantName",
                "representantDocumentId",
                "representantEmail"
            );
            if (!emailRegex.test(formData.representantEmail)) {
                const fieldName = fieldNames["representantEmail"] || "representantEmail";
                setError(`${fieldName} eremuak formatu baliogabea du.`);
                return false;
            }
        }

        if (formData.adventureActivity) {
            requiredFields.push("adventureActivitySelected");
        }

        if (formData.choiceOfActions) {
            if (formData.choiceOfActionsJson.length === 0) {
                setError('Gutxienez ekintza bat gehitu behar da.');
                return false;
            }
            for (const day of formData.choiceOfActionsJson) {
                if (!day.question_eu.trim() || !day.question_es.trim()) {
                    setError('Egun guztietan galdera bat sartu behar da.');
                    return false;
                }
                if (day.activities_eu.length === 0) {
                    setError('Egun guztietan gutxienez ekintza bat sartu behar da.');
                    return false;
                }
                if (day.activities_eu.length !== day.activities_es.length) {
                    setError('Egun bakoitzean hizkuntza desberdinetan aukera kopuru berdina sartu behar da.');
                    return false;
                }
            }
        }

        if (formData.rules) {
            requiredFields.push("rulesFile_eu");
            requiredFields.push("rulesFile_es");
        }

        if (!emailRegex.test(formData.contactEmail)) {
            const fieldName = fieldNames["contactEmail"] || "contactEmail";
            setError(`${fieldName} eremuak formatu baliogabea du.`);
            return false;
        }

        for (const field of requiredFields) {
            if (!formData[field]) {
                const fieldName = fieldNames[field] || field;
                setError(`${fieldName} eremua hutsik dago.`);
                return false;
            }
        }

        return true;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isCompleted) {
            return;
        }

        if (!isActive) {
            if (!validateForm()) {
                return;
            }
        }

        let dataToSend = {};

        if (isActive) {
            dataToSend = {
                form_id: formId,
                validation_date: formData.validationDate,
                insistent_warnings: formData.insistentWarnings
            };
        } else if (numCompletedStudents > 0) {
            // Send specific fields
            dataToSend = {
                form_id: formId,
                name: formData.name,
                validation_date: formData.validationDate,
                insistent_warnings: formData.insistentWarnings,
                start_date: formData.startDate,
                end_date: formData.endDate,
                num_students: formData.numStudents,
                contact_email: formData.contactEmail,
                summer_experiences: formData.summerExperiences
            };
        } else {
            // Send all fields
            dataToSend = {
                form_id: formId,
                name: formData.name,
                validation_date: formData.validationDate,
                insistent_warnings: formData.insistentWarnings,
                start_date: formData.startDate,
                end_date: formData.endDate,
                num_students: formData.numStudents,
                destination: formData.destination,
                course: formData.course,
                cif: formData.cif,
                contact_email: formData.contactEmail,
                journey_information_file_eu: formData.journeyInformationFile_eu,
                journey_information_file_es: formData.journeyInformationFile_es,
                contract_general_file_eu: formData.contractGeneralFile_eu,
                contract_general_file_es: formData.contractGeneralFile_es,
                contract_specific_file_eu: formData.contractSpecificFile_eu,
                contract_specific_file_es: formData.contractSpecificFile_es,
                insurance_file_eu: formData.insuranceFile_eu,
                insurance_file_es: formData.insuranceFile_es,
                adventure_activity: formData.adventureActivity,
                choice_of_actions: formData.choiceOfActions,
                rules: formData.rules,
                summer_experiences: formData.summerExperiences
            };

            if (formData.cif) {
                dataToSend = {
                    ...dataToSend,
                    school_name: formData.schoolName,
                    school_cif: formData.schoolCif,
                    school_address: formData.schoolAddress,
                    school_director_name: formData.schoolDirectorName,
                    school_director_document_id: formData.schoolDirectorDocumentId,
                    school_director_email: formData.schoolDirectorEmail,
                };
            } else {
                dataToSend = {
                    ...dataToSend,
                    representant_name: formData.representantName,
                    representant_document_id: formData.representantDocumentId,
                    representant_email: formData.representantEmail,
                };
            }

            if (formData.adventureActivity) {
                dataToSend = {
                    ...dataToSend,
                    adventure_activity_selected: formData.adventureActivitySelected
                };
            }

            if (formData.choiceOfActions) {
                dataToSend = {
                    ...dataToSend,
                    choice_of_actions_json: JSON.stringify(formData.choiceOfActionsJson)
                };
            }

            if (formData.rules) {
                dataToSend = {
                    ...dataToSend,
                    rules_file_eu: formData.rulesFile_eu,
                    rules_file_es: formData.rulesFile_es
                };
            }
        }
        api.put("/api/forms/form/", dataToSend, {
            headers: {
                "Content-Type": "multipart/form-data"
            }
        })
        .then((response) => {
            setSuccess('Aldaketak zuzen gorde dira.');
        })
        .catch((error) => {
            setError('Ezin izan da galdetegia eguneratu.');
        });
    };

    const handleBack = () => {
        navigate("/home");
    }

    const truncateTitle = (title, maxLength = 64) => {
        return title.length > maxLength ? `${title.substring(0, maxLength)}...` : title;
    };

    return (
        <Fragment>
            <SuccessPopup message={success} onClose={() => setSuccess(null)} />
            <ErrorPopup message={error} onClose={() => setError(null)} />
            <WarningPopup message={warning} onClose={() => setWarning(null)} />
            <div className="main-content">
                <button className="back-button" onClick={handleBack}>Atzera</button>
                <div className="form-content">
                    <div className="form-container">
                        <form className="create-form" onSubmit={handleSubmit}>
                            <span className="form-section-span">Informazio orokorra</span>
                            <div className="field">
                                <label className="field-name">Izena:</label>
                                <input type="text" name="name" value={formData.name} onChange={handleChange} onBlur={validateDates} maxLength={128} disabled={isActive || isCompleted}  />
                            </div>
                            <div className="field">
                                <label className="field-name">Balidazio data:</label>
                                <input type="date" name="validationDate" value={formData.validationDate} onChange={handleChange} onBlur={validateDates} disabled={isCompleted}/>
                            </div>
                            <div className="field">
                                <label className="field-name">Hasiera data:</label>
                                <input type="date" name="startDate" value={formData.startDate} onChange={handleChange} onBlur={validateDates} disabled={isActive || isCompleted}  />
                            </div>
                            <div className="field">
                                <label className="field-name">Amaiera data:</label>
                                <input type="date" name="endDate" value={formData.endDate} onChange={handleChange} disabled={isActive || isCompleted}  />
                            </div>
                            <div className="field">
                                <label className="field-name">Ikasle kopurua:</label>
                                <input type="number" name="numStudents" value={formData.numStudents} onChange={handleChange} min={0} step={1} onInput={(e) => {e.target.value = e.target.value.replace(/[^\d]/g, '');}} disabled={isActive || isCompleted}  />
                            </div>
                            <div className="field">
                                <label className="field-name">Helmuga:</label>
                                <select name="destination" value={formData.destination} onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted}>
                                    <option value="">-</option>
                                    {destinationOptions.map(option => (
                                        <option key={option.value} value={option.value}>{option.text}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="field">
                                <label className="field-name">Kurtsoa:</label>
                                <select name="course" value={formData.course} onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted}>
                                    <option value="">-</option>
                                    {courseOptions.map(option => (
                                        <option key={option.value} value={option.value}>{option.text}</option>
                                    ))}
                                </select>
                            </div>
                            <span className="form-section-span not-first">Kontaktua</span>
                            <div className="field">
                                <label className="field-name">CIFa:</label>
                                <label className="switch">
                                    <input type="checkbox" name="cif" checked={formData.cif} onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                    <span className={`slider round ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}></span>
                                </label>
                            </div>
                            {formData.cif && (
                                <>
                                    <div className="field">
                                        <label className="field-name">Ikastetxearen izena:</label>
                                        <input type="text" name="schoolName" value={formData.schoolName} onChange={handleChange} maxLength={128} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                    </div>
                                    <div className="field">
                                        <label className="field-name">Ikastetxearen CIFa:</label>
                                        <input type="text" name="schoolCif" value={formData.schoolCif} onChange={handleChange} maxLength={32}  onBlur={validateCif} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                    </div>
                                    <div className="field">
                                        <label className="field-name">Ikastetxearen helbidea:</label>
                                        <input type="text" name="schoolAddress" value={formData.schoolAddress} onChange={handleChange} maxLength={32} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                    </div>
                                    <div className="field">
                                        <label className="field-name">Zuzendariaren izena:</label>
                                        <input type="text" name="schoolDirectorName" value={formData.schoolDirectorName} onChange={handleChange} maxLength={128} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                    </div>
                                    <div className="field">
                                        <label className="field-name">Zuzendarianen NANa:</label>
                                        <input type="text" name="schoolDirectorDocumentId" value={formData.schoolDirectorDocumentId} onChange={handleChange} onBlur={validateNanCIF} maxLength={32} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                    </div>
                                    <div className="field">
                                        <label className="field-name">Zuzendariaren emaila:</label>
                                        <input type="text" name="schoolDirectorEmail" value={formData.schoolDirectorEmail} onChange={handleChange} maxLength={128} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                    </div>
                                </>
                            )}
                            {!formData.cif && (
                                <>
                                    <div className="field">
                                        <label className="field-name">Ordezkariaren izena:</label>
                                        <input type="text" name="representantName" value={formData.representantName} onChange={handleChange} maxLength={128} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                    </div>
                                    <div className="field">
                                        <label className="field-name">Ordezkariaren NANa:</label>
                                        <input type="text" name="representantDocumentId" value={formData.representantDocumentId} onChange={handleChange} onBlur={validateNanNotCIF} maxLength={32} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                    </div>
                                    <div className="field">
                                        <label className="field-name">Ordezkariaren emaila:</label>
                                        <input type="text" name="representantEmail" value={formData.representantEmail} onChange={handleChange} maxLength={128} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                    </div>
                                </>
                            )}
                            <div className="field">
                                <label className="field-name">Kontaktu emaila:</label>
                                <input type="text" name="contactEmail" value={formData.contactEmail} onChange={handleChange} maxLength={128} disabled={isActive || isCompleted}  />
                            </div>
                            <span className="form-section-span not-first">Dokumentazioa eta jarduerak</span>
                            <div className="field">
                                <label className="field-name">Aukeratutakoa:</label>
                                <div className="file-input">
                                    <label htmlFor="journey-information-file-eu" className={isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}>Aukeratu fitxategia (EU)</label>
                                    <input type="file" id="journey-information-file-eu" name="journeyInformationFile_eu" onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                </div>
                                <span className={`file-input-span ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}>
                                    {!formData.journeyInformationFile_eu && (<p>Ez da aukeratu fitxategirik.</p>)}
                                    {formData.journeyInformationFile_eu && formData.journeyInformationFile_eu.url && (<p><a href={formData.journeyInformationFile_eu.url} target="_blank" rel="noreferrer">{formData.journeyInformationFile_eu.name}</a></p>)}
                                    {formData.journeyInformationFile_eu && !formData.journeyInformationFile_eu.url && (<p><a href={URL.createObjectURL(formData.journeyInformationFile_eu)} target="_blank" rel="noreferrer">{formData.journeyInformationFile_eu.name}</a></p>)}
                                </span>
                            </div>
                            <div className="field">
                                <label className="field-name"></label>
                                <div className="file-input">
                                    <label htmlFor="journey-information-file-es" className={isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}>Aukeratu fitxategia (ES)</label>
                                    <input type="file" id="journey-information-file-es" name="journeyInformationFile_es" onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                </div>
                                <span className={`file-input-span ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}>
                                    {!formData.journeyInformationFile_es && (<p>Ez da aukeratu fitxategirik.</p>)}
                                    {formData.journeyInformationFile_es && formData.journeyInformationFile_es.url && (<p><a href={formData.journeyInformationFile_es.url} target="_blank" rel="noreferrer">{formData.journeyInformationFile_es.name}</a></p>)}
                                    {formData.journeyInformationFile_es && !formData.journeyInformationFile_es.url && (<p><a href={URL.createObjectURL(formData.journeyInformationFile_es)} target="_blank" rel="noreferrer">{formData.journeyInformationFile_es.name}</a></p>)}
                                </span>
                            </div>
                            <div className="field">
                                <label className="field-name">Kontratu orokorra:</label>
                                <div className="file-input">
                                    <label htmlFor="contract-general-file-eu" className={isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}>Aukeratu fitxategia (EU)</label>
                                    <input type="file" id="contract-general-file-eu" name="contractGeneralFile_eu" onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                </div>
                                <span className={`file-input-span ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}>
                                    {!formData.contractGeneralFile_eu && (<p>Ez da aukeratu fitxategirik.</p>)}
                                    {formData.contractGeneralFile_eu && formData.contractGeneralFile_eu.url && (<p><a href={formData.contractGeneralFile_eu.url} target="_blank" rel="noreferrer">{formData.contractGeneralFile_eu.name}</a></p>)}
                                    {formData.contractGeneralFile_eu && !formData.contractGeneralFile_eu.url && (<p><a href={URL.createObjectURL(formData.contractGeneralFile_eu)} target="_blank" rel="noreferrer">{formData.contractGeneralFile_eu.name}</a></p>)}
                                </span>
                            </div>
                            <div className="field">
                                <label className="field-name"></label>
                                <div className="file-input">
                                    <label htmlFor="contract-general-file-es" className={isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}>Aukeratu fitxategia (ES)</label>
                                    <input type="file" id="contract-general-file-es" name="contractGeneralFile_es" onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                </div>
                                <span className={`file-input-span ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}>
                                    {!formData.contractGeneralFile_es && (<p>Ez da aukeratu fitxategirik.</p>)}
                                    {formData.contractGeneralFile_es && formData.contractGeneralFile_es.url && (<p><a href={formData.contractGeneralFile_es.url} target="_blank" rel="noreferrer">{formData.contractGeneralFile_es.name}</a></p>)}
                                    {formData.contractGeneralFile_es && !formData.contractGeneralFile_es.url && (<p><a href={URL.createObjectURL(formData.contractGeneralFile_es)} target="_blank" rel="noreferrer">{formData.contractGeneralFile_es.name}</a></p>)}
                                </span>
                            </div>
                            <div className="field">
                                <label className="field-name">Kontratu zehatza:</label>
                                <div className="file-input">
                                    <label htmlFor="contract-specific-file-eu" className={isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}>Aukeratu fitxategia (EU)</label>
                                    <input type="file" id="contract-specific-file-eu" name="contractSpecificFile_eu" onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                </div>
                                <span className={`file-input-span ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}>
                                    {!formData.contractSpecificFile_eu && (<p>Ez da aukeratu fitxategirik.</p>)}
                                    {formData.contractSpecificFile_eu && formData.contractSpecificFile_eu.url && (<p><a href={formData.contractSpecificFile_eu.url} target="_blank" rel="noreferrer">{formData.contractSpecificFile_eu.name}</a></p>)}
                                    {formData.contractSpecificFile_eu && !formData.contractSpecificFile_eu.url && (<p><a href={URL.createObjectURL(formData.contractSpecificFile_eu)} target="_blank" rel="noreferrer">{formData.contractSpecificFile_eu.name}</a></p>)}
                                </span>
                            </div>
                            <div className="field">
                                <label className="field-name"></label>
                                <div className="file-input">
                                    <label htmlFor="contract-specific-file-es" className={isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}>Aukeratu fitxategia (ES)</label>
                                    <input type="file" id="contract-specific-file-es" name="contractSpecificFile_es" onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                </div>
                                <span className={`file-input-span ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}>
                                    {!formData.contractSpecificFile_es && (<p>Ez da aukeratu fitxategirik.</p>)}
                                    {formData.contractSpecificFile_es && formData.contractSpecificFile_es.url && (<p><a href={formData.contractSpecificFile_es.url} target="_blank" rel="noreferrer">{formData.contractSpecificFile_es.name}</a></p>)}
                                    {formData.contractSpecificFile_es && !formData.contractSpecificFile_es.url && (<p><a href={URL.createObjectURL(formData.contractSpecificFile_es)} target="_blank" rel="noreferrer">{formData.contractSpecificFile_es.name}</a></p>)}
                                </span>
                            </div>
                            <div className="field">
                                <label className="field-name">Asegurua:</label>
                                <div className="file-input">
                                    <label htmlFor="insurance-file-eu" className={isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}>Aukeratu fitxategia (EU)</label>
                                    <input type="file" id="insurance-file-eu" name="insuranceFile_eu" onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                </div>
                                <span className={`file-input-span ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}>
                                    {!formData.insuranceFile_eu && (<p>Ez da aukeratu fitxategirik.</p>)}
                                    {formData.insuranceFile_eu && formData.insuranceFile_eu.url && (<p><a href={formData.insuranceFile_eu.url} target="_blank" rel="noreferrer">{formData.insuranceFile_eu.name}</a></p>)}
                                    {formData.insuranceFile_eu && !formData.insuranceFile_eu.url && (<p><a href={URL.createObjectURL(formData.insuranceFile_eu)} target="_blank" rel="noreferrer">{formData.insuranceFile_eu.name}</a></p>)}
                                </span>
                            </div>
                            <div className="field">
                                <label className="field-name"></label>
                                <div className="file-input">
                                    <label htmlFor="insurance-file-es" className={isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}>Aukeratu fitxategia (ES)</label>
                                    <input type="file" id="insurance-file-es" name="insuranceFile_es" onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                </div>
                                <span className={`file-input-span ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}>
                                    {!formData.insuranceFile_es && (<p>Ez da aukeratu fitxategirik.</p>)}
                                    {formData.insuranceFile_es && formData.insuranceFile_es.url && (<p><a href={formData.insuranceFile_es.url} target="_blank" rel="noreferrer">{formData.insuranceFile_es.name}</a></p>)}
                                    {formData.insuranceFile_es && !formData.insuranceFile_es.url && (<p><a href={URL.createObjectURL(formData.insuranceFile_es)} target="_blank" rel="noreferrer">{formData.insuranceFile_es.name}</a></p>)}
                                </span>
                            </div>
                            <div className="field">
                                <label className="field-name">Abenturazko ekintzak:</label>
                                <label className="switch">
                                    <input type="checkbox" name="adventureActivity" checked={formData.adventureActivity} onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                    <span className={`slider round ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}></span>
                                </label>
                            </div>
                            {formData.adventureActivity && (
                                <div className="field">
                                <div className="select-input">
                                        <select className="adventure-select" id="adventure-activities-selected" name="adventureActivitySelected" value={formData.adventureActivitySelected} onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted}>
                                            <option value="">-</option>
                                            {adventureActivityOptions.map((activity) => (
                                                <option key={activity.id} value={activity.id}>
                                                    {truncateTitle(activity.title_eu)}
                                                </option>
                                            ))}
                                        </select>
                                    </div>
                                </div>
                            )}
                            <div className="field">
                                <label className="field-name">Ekintzak aukeratu:</label>
                                <label className="switch">
                                    <input type="checkbox" name="choiceOfActions" checked={formData.choiceOfActions} onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                    <span className={`slider round ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}></span>
                                </label>
                            </div>
                            {formData.choiceOfActions && (
                                <div className="day-add">
                                    <button className="simple-button day-add-button" type="button" onClick={handleAddDay} disabled={isActive || numCompletedStudents > 0 || isCompleted}>Gehitu +</button>
                                </div>
                            )}
                            {formData.choiceOfActions && formData.choiceOfActionsJson.map((day, dayIndex) => (
                                <div key={dayIndex} className="day-section">
                                    <div className="day-remove">
                                        <BsX className={`bigger-icon day-remove-icon ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`} onClick={() => {
                                            if (!(isActive || numCompletedStudents > 0 || isCompleted)) {
                                                handleRemoveDay(dayIndex);
                                            }
                                        }} />
                                    </div>
                                    <div className="field day-field text-field">
                                        <label className="field-name">Galdera (EU):</label>
                                        <input
                                            type="text"
                                            value={day.question_eu}
                                            onChange={(e) => handleDayQuestionChangeEU(e, dayIndex)}
                                            maxLength={128}
                                            disabled={isActive || numCompletedStudents > 0 || isCompleted}
                                        />
                                    </div>
                                    <div className="field day-field text-field">
                                        <label className="field-name">Galdera (ES):</label>
                                        <input
                                            type="text"
                                            value={day.question_es}
                                            onChange={(e) => handleDayQuestionChangeES(e, dayIndex)}
                                            maxLength={128}
                                            disabled={isActive || numCompletedStudents > 0 || isCompleted}
                                        />
                                    </div>
                                    <div className="field day-field">
                                        <label className="field-name">Aukeraketa anitza:</label>
                                        <label className="switch">
                                            <input
                                                type="checkbox"
                                                checked={day.choiceOfActionsMult}
                                                onChange={(e) => handleDayMultChange(e, dayIndex)}
                                                disabled={isActive || numCompletedStudents > 0 || isCompleted}
                                            />
                                            <span className={`slider round ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}></span>
                                        </label>
                                    </div>
                                    <div className="field day-field">
                                        <label className="field-name">Aukeren zerrenda (EU):</label>
                                        <div className="input-with-icon">
                                            <input type="text" ref={el => newActionRefsEU.current[dayIndex] = el} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                            <span className={`create-item-span ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`} onClick={(e) => {
                                                if (!(isActive || numCompletedStudents > 0 || isCompleted)) {
                                                    handleAddActionEU(e, dayIndex);
                                                }
                                            }}>
                                                <BsPlusCircleFill />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="item-list-container">
                                        <ul className="item-list">
                                            {day.activities_eu?.length > 0 ? (
                                                day.activities_eu.map((activity, activityIndex) => (
                                                    <li key={activityIndex} className="item-with-icon">
                                                        <span>{activity}</span>
                                                        <span className={`delete-item-span ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`} onClick={() => {
                                                            if (!(isActive || numCompletedStudents > 0 || isCompleted)) {
                                                                handleRemoveActionEU(dayIndex, activityIndex);
                                                            }
                                                        }}>
                                                            <BsX />
                                                        </span>
                                                    </li>
                                                ))
                                            ): (<></>)}
                                        </ul>
                                    </div>
                                    <div className="field day-field">
                                        <label className="field-name">Aukeren zerrenda (ES):</label>
                                        <div className="input-with-icon">
                                            <input type="text" ref={el => newActionRefsES.current[dayIndex] = el} disabled={isActive || numCompletedStudents > 0 || isCompleted} />
                                            <span className={`create-item-span ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`} onClick={(e) => {
                                                if (!(isActive || numCompletedStudents > 0 || isCompleted)) {
                                                    handleAddActionES(e, dayIndex);
                                                }
                                            }}>
                                                <BsPlusCircleFill />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="item-list-container">
                                        <ul className="item-list">
                                            {day.activities_es?.length > 0 ? (
                                                day.activities_es.map((activity, activityIndex) => (
                                                    <li key={activityIndex} className="item-with-icon">
                                                        <span>{activity}</span>
                                                        <span className={`delete-item-span ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`} onClick={() => {
                                                            if (!(isActive || numCompletedStudents > 0 || isCompleted)) {
                                                                handleRemoveActionES(dayIndex, activityIndex);
                                                            }
                                                        }}>
                                                            <BsX />
                                                        </span>
                                                    </li>
                                                ))
                                            ) : (<></>)}
                                        </ul>
                                    </div>
                                </div>
                            ))}
                            <div className="field">
                                <label className="field-name">Arauak:</label>
                                <label className="switch">
                                    <input type="checkbox" name="rules" checked={formData.rules} onChange={handleChange} disabled={isActive || numCompletedStudents > 0 || isCompleted}/>
                                    <span className={`slider round ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}></span>
                                </label>
                            </div>
                            {formData.rules && (
                                <>
                                <div className="field">
                                    <label className="field-name"/>
                                    <div className="file-input">
                                        <label htmlFor="rules-file-eu" className={isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}>Aukeratu fitxategia (EU)</label>
                                        <input type="file" id="rules-file-eu" name="rulesFile_eu" onChange={handleChange} maxLength={64} disabled={isActive || numCompletedStudents > 0 || isCompleted}/>
                                    </div>
                                    <span className={`file-input-span ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}>
                                        {!formData.rulesFile_eu && (<p>Ez da aukeratu fitxategirik.</p>)}
                                        {formData.rulesFile_eu && formData.rulesFile_eu.url && (<p><a href={formData.rulesFile_eu.url} target="_blank" rel="noreferrer">{formData.rulesFile_eu.name}</a></p>)}
                                        {formData.rulesFile_eu && !formData.rulesFile_eu.url && (<p><a href={URL.createObjectURL(formData.rulesFile_eu)} target="_blank" rel="noreferrer">{formData.rulesFile_eu.name}</a></p>)}
                                    </span>
                                </div>
                                <div className="field">
                                    <label className="field-name"/>
                                    <div className="file-input">
                                        <label htmlFor="rules-file-es" className={isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}>Aukeratu fitxategia (ES)</label>
                                        <input type="file" id="rules-file-es" name="rulesFile_es" onChange={handleChange} maxLength={64} disabled={isActive || numCompletedStudents > 0 || isCompleted}/>
                                    </div>
                                    <span className={`file-input-span ${isActive || numCompletedStudents > 0 || isCompleted ? 'disabled' : ''}`}>
                                        {!formData.rulesFile_es && (<p>Ez da aukeratu fitxategirik.</p>)}
                                        {formData.rulesFile_es && formData.rulesFile_es.url && (<p><a href={formData.rulesFile_es.url} target="_blank" rel="noreferrer">{formData.rulesFile_es.name}</a></p>)}
                                        {formData.rulesFile_es && !formData.rulesFile_es.url && (<p><a href={URL.createObjectURL(formData.rulesFile_es)} target="_blank" rel="noreferrer">{formData.rulesFile_es.name}</a></p>)}
                                    </span>
                                </div>
                                </>
                            )}
                            <div className="field">
                                <label className="field-name">Notiziak/Promozioak:</label>
                                <label className="switch">
                                    <input type="checkbox" name="summerExperiences" checked={formData.summerExperiences} onChange={handleChange} disabled={isActive || isCompleted || isCompleted}  />
                                    <span className={`slider round ${isActive || isCompleted ? 'disabled' : ''}`}></span>
                                </label>
                            </div>
                            <span className="form-section-span not-first">Abisuak</span>
                            <div className="field">
                                <label className="field-name">Abisu insistenteak:</label>
                                <label className="switch">
                                    <input type="checkbox" name="insistentWarnings" checked={formData.insistentWarnings} onChange={handleChange} disabled={isCompleted}/>
                                    <span className={`slider round ${isCompleted ? 'disabled' : ''}`}></span>
                                </label>
                            </div>
                            <button className="create-button" type="submit" disabled={isCompleted}>Gorde</button>
                        </form>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
