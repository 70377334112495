import React, { useState, useEffect, useCallback, Fragment  } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Table } from 'react-bootstrap';
import api from '../../api/api';
import { FormInstancesElement } from './FormInstancesElement';
import ErrorPopup from '../Alerts/ErrorPopup';
import './Forms.css';

export function FormInstances() {
    const navigate = useNavigate();
    const { formId } = useParams();
    const [error, setError] = useState(null);
    const [formInstances, setFormInstances] = useState([]);

    const handleBack = () => {
        navigate("/home");
    }

    const fetchFormInstances = useCallback(async () => {
        api.get(`/api/forms/form_instances_list/?form_id=${formId}`)
        .then((response) => {
            setFormInstances(response.data);
        })
        .catch((error) => {
            setError('Ezin izan dira galdetegiaren instantziak eskuratu.');
        });
    }, []);

    useEffect(() => {
        fetchFormInstances(); // Llama a fetchForms al montar el componente
    }, [fetchFormInstances]);

    const handleDeleteFormInstance = (formInstanceUuid) => {
        api.delete('/api/forms/form_instance/', { data: { form_instance_uuid: formInstanceUuid } })
        .then((response) => {
            setFormInstances(formInstances.filter(form_instance => form_instance.uuid !== formInstanceUuid));
        })
        .catch((error) => {
            setError('Ezin izan da galdetegia ezabatu.');
        });
            
    };

    return (
        <Fragment>
        <ErrorPopup message={error} onClose={() => setError(null)} />
        <div className='main-content'>
            <div className='table-head-options'>
                <button className="back-button" onClick={handleBack}>Atzera</button>
            </div>
            <div className='table-main'>
                <div className="table-elements">
                    <Table>
                        <thead className="forms-header">
                            <tr>
                                <th>Izena</th>
                                <th>Abizena 1</th>
                                <th>Abizena 2</th>
                                <th>Generoa</th>
                                <th>Jaiotze data</th>
                                <th>Helbidea</th>
                                <th>Herria</th>
                                <th>Posta kodea</th>
                                <th>Nazionalitatea</th>
                                <th>Dokumentu mota</th>
                                <th>Dokumentu zbkia</th>
                                <th>Bidaltze data</th>
                                <th>Iraungitze data</th>
                                <th>Tlf. Zbkia</th>
                                <th>1. Tutorearen izena</th>
                                <th>1. Tutorearen dokumentu mota</th>
                                <th>1. Tutorearen dokumentu zbkia</th>
                                <th>1. Tutorearen emaila</th>
                                <th>1. Tutorearen tlf. zbkia</th>
                                <th>2. Tutorearen izena</th>
                                <th>2. Tutorearen dokumentu mota</th>
                                <th>2. Tutorearen dokumentu zbkia</th>
                                <th>2. Tutorearen emaila</th>
                                <th>2. Tutorearen tlf. zbkia</th>
                                <th>Janari alergiak</th>
                                <th>Bestelako alergiak</th>
                                <th>Gaixotasunak</th>
                                <th>Komunikatu?</th>
                                <th>Klasea</th>
                                <th>Kamiseta neurria</th>
                                <th>Abenturazko ekintzak</th>
                                <th>Arduradunaren komunikabideetan argitaratu?</th>
                                <th>Arduradunaz kanpoko komunikabideetan argitaratu?</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <th colSpan={33} style={{ width: '100%' }}>
                                    <hr />
                                </th>
                            </tr>
                            {formInstances.length === 0 ? (
                                <tr>
                                    <td className='no-forms' colSpan={33}>
                                        <div>Ez dago galdetegiaren instantziarik</div>
                                    </td>
                                </tr>
                            ) : (
                                formInstances.map((formInstance) => (
                                    <FormInstancesElement
                                        key={formInstance.uuid}
                                        formInstance={formInstance}
                                        handleDeleteFormInstance={handleDeleteFormInstance}
                                    />
                                ))
                            )}
                        </tbody>
                    </Table>
                </div>
            </div>
        </div>
        </Fragment>
    );
}
