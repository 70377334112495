import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import api from "../../api/api";
import { BsCardList, BsEye, BsTrash3, BsPencil, BsCloudDownload, BsLink45Deg, BsCheckCircle, BsCheckCircleFill, BsCopy, BsX } from 'react-icons/bs';
import MessagePopup from '../Alerts/MessagePopup';
import ErrorPopup from '../Alerts/ErrorPopup';
import SuccessPopup from '../Alerts/SuccessPopup';
import './Forms.css';

export function FormsElement({ form, onDeleteForm }) {
    const navigate = useNavigate();
    const [formState, setFormState] = useState(form);
    const [wpPage, setWPPage] = useState({});
    const [success, setSuccess] =useState(null);
    const [message, setMessage] = useState(null);
    const [error, setError] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(false);
    const [updateWP, setUpdateWP] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleActivate = (form_id, is_active) => {
        if (!formState.completed){
            api.post('/api/forms/activate/', { form_id, is_active: !is_active })
            .then((response) => {
                setFormState((prevState) => ({
                    ...prevState,
                    is_active: !is_active
                }));
            })
            .catch((error) => {
                setError('Ezin izan da galdetegia aktibatu.')
            });
        }
    };

    const handleFormInstances = () => {
        navigate(`/form_instances/${form.id}`);
    };

    const handlePreview = () => {
        window.open(form.preview_link, '_blank');
    };

    const handleWP = () => {
        // Obtener los datos de Wordpress.
        getWPData(form.id);
        setUpdateWP(true);
    }

    const handleCopyLink = () => {
        navigator.clipboard.writeText(wpPage.link);
        setMessage('Link-a zuzen kopiatu da.')
    }

    const handleEdit = () => {
        navigate(`/edit_form/${form.id}`);
    };

    const handleComplete = (form_id, completed) => {
        api.post('/api/forms/complete/', { form_id, completed: completed })
        .then((response) => {
            if (completed){
                setFormState((prevState) => ({
                    ...prevState,
                    completed: completed,
                    is_active: false,
                    num_students: formState.num_completed
                }));
            } else {
                setFormState((prevState) => ({
                    ...prevState,
                    completed: completed
                }));
            }
            
        })
        .catch((error) => {
            setError('Ezin izan da galdetegia amaitutzat eman.')
        });
    };

    const handleDelete = (form_id) => {
        onDeleteForm(form_id);
    };

    const handleDownload = (form_id) => {
        api.get(`/api/forms/download_reports/?form_id=${form_id}`, {
            responseType: 'blob', // Important for binary data
        })
        .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            const actualDate = new Date().toISOString().slice(0, 10).replace(/-/g, '');
            link.href = url;
            link.setAttribute('download', `${actualDate}_${form.name}_datuak.xlsx`); // or any other extension
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch((error) => {
            setError('Ezin izan dira datuak deskargatu.')
        });
    }

    const getWPData = (form_id) => {
        api.get(`/api/forms/wp_page/?form_id=${form_id}`)
        .then((response) => {
            setWPPage(response.data);
        })
        .catch((error) => {
            setError('Ezin izan dira datuak deskargatu.')
        });
    }

    const handleDeleteConfirm = () => {
        setConfirmDelete(true);
    };

    const handleDeleteCancel = () => {
        setConfirmDelete(false);
    };

    const handleDeleteConfirmProceed = () => {
        handleDelete(form.id);
        setConfirmDelete(false);
    };

    const handleOverlayClick = (event) => {
        if (event.target.className === 'modal-overlay') {
            setConfirmDelete(false);
        }
    };

    const handleWPTitleSave = () => {
        setIsSubmitting(true);
        api.post('/api/forms/wp_title/', { 
            form_id: form.id, 
            title: wpPage.title 
        })
        .then((response) => {
            setSuccess('Izenburua ondo eguneratu da.');
            getWPData(form.id);
            setIsSubmitting(false);
        })
        .catch((error) => {
            setError('Ezin izan da izenburua eguneratu.');
            setIsSubmitting(false);
        });
    };
    
    const handleWPLinkSave = () => {
        setIsSubmitting(true);
        api.post('/api/forms/wp_slug/', { 
            form_id: form.id, 
            slug: wpPage.slug 
        })
        .then((response) => {
            setSuccess('Link-a ondo eguneratu da.');
            getWPData(form.id);
            setIsSubmitting(false);
        })
        .catch((error) => {
            setError('Ezin izan da link-a eguneratu.');
            setIsSubmitting(false);
        });
    };

    // Agregamos las funciones onChange para actualizar el estado
    const handleTitleChange = (e) => {
        setWPPage((prevState) => ({
            ...prevState,
            title: e.target.value,
        }));
    };

    const handleLinkChange = (e) => {
        setWPPage((prevState) => ({
            ...prevState,
            slug: e.target.value,
        }));
    };

    return (
        <>
            <MessagePopup message={message} onClose={() => setMessage(null)} />
            <ErrorPopup message={error} onClose={() => setError(null)} />
            <SuccessPopup message={success} onClose={() => setSuccess(null)} />
            <tr>
                <td>
                    <div className='clickable-icons'>
                        <span
                            className={formState.num_completed === 0 ? 'unclickable-span' : 'clickable-span'}
                            onClick={formState.num_completed !== 0 ? () => handleFormInstances(formState.id) : null}
                        >
                            <BsCardList />
                        </span>
                    </div>        
                </td>
                <td>{formState.name}</td>
                <td>{formState.destination}</td>
                <td>{formState.start_date}</td>
                <td>{formState.end_date}</td>
                <td>{formState.contact_email}</td>
                <td>{formState.user}</td>
                <td>{formState.num_completed}/{formState.num_students}</td>
                <td className='td-right'>
                    <div className='clickable-icons'>
                        <span className='clickable-span' onClick={() => handlePreview(formState.id)}>
                            <BsEye />
                        </span>
                        {' '}
                        <span className='clickable-span' onClick={() => handleEdit(formState.id)}>
                            <BsPencil />
                        </span>
                        {' '}
                        <span className='clickable-span' onClick={() => handleWP(formState.id)}>
                            <BsLink45Deg className='bigger-icon-2'/>
                        </span>
                        {' '}
                        <span
                            className={formState.num_completed === 0 ? 'unclickable-span' : 'clickable-span'}
                            onClick={formState.num_completed !== 0 ? () => handleDownload(formState.id) : null}
                        >
                            <BsCloudDownload />
                        </span>
                        {' '}
                        <span className='clickable-span' onClick={handleDeleteConfirm}>
                            <BsTrash3 />
                        </span>
                        {' '}
                        {formState.completed && (
                            <span className='clickable-span' onClick={() => handleComplete(formState.id, false)}>
                                <BsCheckCircleFill className='bigger-icon green-color'/>
                            </span>
                        )}
                        {!formState.completed && (
                            <span className='clickable-span' onClick={() => handleComplete(formState.id, true)}>
                                <BsCheckCircle className='bigger-icon'/>
                            </span>
                        )}
                    </div>
                    {confirmDelete && (
                        <div className="modal-overlay" onClick={handleOverlayClick}>
                            <div className="modal-content">
                                <p>Ziur zaude galdetegia ezabatu nahi duzula?</p>
                                <button className='delete-button' onClick={handleDeleteConfirmProceed}>Ezabatu</button>
                                <button className='back-button' onClick={handleDeleteCancel}>Atzera</button>
                            </div>
                        </div>
                    )}
                    {updateWP && (
                        <div className="modal-overlay" onClick={handleOverlayClick}>
                            <div className="modal-content link-update">
                                <span className='close-modal-span' onClick={() => setUpdateWP(false)}>
                                    <BsX />
                                </span>
                                <p className='modal-name'>WordPress orrialdearen datuak</p>
                                <div>
                                    <input className='inmutable-input' type='text' value={wpPage.link}></input>
                                    <span className='icon-span' onClick={() => handleCopyLink()}>
                                        <BsCopy />
                                    </span>
                                </div>
                                <div className='update-div'>
                                    <span>
                                        Izenburua:
                                    </span>
                                    <input type='text' value={wpPage.title} onChange={handleTitleChange}></input>
                                </div>
                                <button className='create-button' onClick={handleWPTitleSave} disabled={isSubmitting}>Gorde</button>
                                <div className='update-div'>
                                    <span>Link-a:</span>
                                    <input type='text' value={wpPage.slug} onChange={handleLinkChange}></input>
                                </div>
                                <button className='create-button' onClick={handleWPLinkSave} disabled={isSubmitting}>Gorde</button>
                            </div>
                        </div>
                    )}
                    <label className="switch">
                        <input
                            type="checkbox"
                            checked={formState.is_active}
                            onChange={() => handleActivate(formState.id, formState.is_active)}
                        />
                        <span className="slider"></span>
                    </label>
                </td>
            </tr>
        </>
    );
}
